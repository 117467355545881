import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Link from "gatsby-plugin-transition-link"
import {
  pageTransitionIn,
  pageTransitionOut,
} from "../transitions/pageTransition"

function template(i, duration, tag) {
  return `
      &:nth-child(${i + 1}) ${tag}{
        transition-delay: ${`calc(${duration}s * ${i + 1})`};
       }
    `
}

function getAnimations() {
  let str = ""
  for (let index = 0; index < 2; index += 1) {
    str += template(index, 0.25, ".categoryImageWrapper")
  }
  return str
}

const CategoryWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  /* margin-bottom:2rem; */
  overflow: hidden;

  ${getAnimations()}

  &.is-inview .categoryImageWrapper {
    transition-delay: 0s !important;
  }

  &:last-of-type {
    .heading,
    .clone {
      /* left: -11vw; */
    }
  }

  .categoryImageWrapper-1,
  .categoryImageWrapper-2 {
    opacity: 0;
    transition: 0.5s all ease-in-out;
  }

  .categoryImageWrapper-1 {
    @media only screen and (min-width: 860px) {
      transform: translateX(-100px);
    }
  }
  .categoryImageWrapper-2 {
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;

    @media only screen and (min-width: 860px) {
      transform: translateX(100px);
      position: relative;
    }
  }

  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: clamp(40vh, 80%, 64vh);
  }

  &.is-inview {
    .categoryImageWrapper-1,
    .categoryImageWrapper-2 {
      opacity: 1;
      transform: translateX(0);
    }
  }

  a {
    /* grid-gap:2rem; */
    display: grid;
    grid-template-columns: 1fr;

    @media only screen and (min-width: 860px) {
      grid-gap: 25px;
      grid-template-columns: 1fr 1fr;
    }

    color: inherit;
  }

  &:hover {
    h2,
    .clone {
      opacity: 1;
      color: var(--text-color-switcher);
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  color: var(--bg-color);
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.categoryImageWrapper-2 {
    grid-column-start: 2;
  }
`

const HeadingWrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 120px;
  font-family: "Oswald";
  text-transform: uppercase;

  h2,
  .clone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(2);
    transition: 0.5s all cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-delay: 0.3s;
    font-size: clamp(3rem, 9vw, 16rem);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--text-color-switcher);
    color: var(--text-color-switcher);

    @media only screen and (min-width: 860px) {
      color: transparent;
    }
    /* margin-top:-21px !important; */
  }

  .clone {
    left: 100vw;
  }

  &.is-inview {
    h2,
    .clone {
      opacity: 1;
      transform: scale(1);
      transition-delay: 0s;
    }
  }
`

export default function HomepageCategory(props) {
  const slug = props.slug
  const title = props.title
  const image1 = props?.image1?.localFile?.childImageSharp?.gatsbyImageData
  const image2 = props?.image2?.localFile?.childImageSharp?.gatsbyImageData

  return (
    <CategoryWrapper data-scroll data-scroll-offset="10">
      <Link
        to={slug}
        className="categoryLink"
        title={title}
        exit={{
          trigger: pageTransitionIn,
          length: 1,
        }}
        entry={{
          trigger: pageTransitionOut,
          delay: 1,
        }}
      >
        <ImageWrapper className="categoryImageWrapper-1 categoryImageWrapper">
          <GatsbyImage image={image1} className="categoryImage" />
        </ImageWrapper>

        <ImageWrapper className="categoryImageWrapper-2 categoryImageWrapper">
          <GatsbyImage image={image2} className="categoryImage" />
        </ImageWrapper>

        <HeadingWrapper
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed={"1"}
          className="categoryImageWrapper"
        >
          <h2 className="heading">{title}</h2>
          {/* <div className="clone">{title}</div> */}
        </HeadingWrapper>
      </Link>
    </CategoryWrapper>
  )
}
