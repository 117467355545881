import React, { Component } from "react"
import IntroText from "../components/introText"
import locomotiveScroll from "locomotive-scroll"
import { HeaderFunc } from "../scrollFunctions/headerFunc"
import FlexibleContent from "../components/FlexibleContent"
import Header from "../components/header"
import HomepageCategories from "../components/HomepageCategories"
import { graphql } from "gatsby"
import IconWrap from "../components/IconWrap"
import CurrentPage from "../components/CurrentPageIdentifier"
import { Helmet } from "react-helmet"
import gsap from "gsap"

export default class Page extends Component {
  constructor() {
    super()
    this.state = { scroll: {} }
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const images = Array.from(document.querySelectorAll(".image-wrap"))

    const checkInviewImages = () => {
      if (typeof window !== "undefined") {
        const inviewImages = images.filter(image => {
          const imgRect = image.getBoundingClientRect()
          const wh = window.innerHeight

          return imgRect.top <= wh && imgRect.top > 0
        })

        gsap.to(inviewImages, { opacity: 1, y: 0, stagger: 0.3, delay: 0.5 })
      }
    }

    const scroll = new locomotiveScroll({
      el: this.scrollRef.current,
      smooth: true,
      smoothMobile: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
      offset: ["30%", 0],
    })

    scroll.on("call", func => {
      if (func === "headerFunc") {
        HeaderFunc()
      }
    })

    scroll.on("scroll", checkInviewImages)

    this.setState({ scroll })
  }

  componentDidUpdate() {
    this.state.scroll.update()
  }

  componentWillUnmount() {}

  render() {
    const { data } = this.props

    return (
      <div>
        <Helmet>
          <title>{this.props.data.page.title} | David Lindsay</title>
          <meta
            name="description"
            content={`${this.props.data.page.title} Photography | Photographer | Tadcaster | Yorkshire | @photosbydavid `}
          />
        </Helmet>
        <IconWrap />
        <CurrentPage
          title={
            this.props.data.page.title === "Home"
              ? "David Lindsay"
              : this.props.data.page.title
          }
        />
        <div className="scroller" ref={this.scrollRef}>
          <Header
            className="header"
            state={this.state}
            headerText={
              this.props.data.page.title === "Home"
                ? "David Lindsay"
                : this.props.data.page.title
            }
          />

          {
            /* If is Homepage show Homepge categories */
            data?.page?.title === "Home" ? (
              <HomepageCategories HomepageCategories={data.page.acf_home} />
            ) : (
              ""
            )
          }

          {
            /* If has Intro Text, show it */
            data.page.content ? (
              <IntroText content={data.page.content}></IntroText>
            ) : (
              ""
            )
          }

          {
            /* If has Flexible Content, show it */
            data.page.acfFlexibleContent ? (
              <FlexibleContent
                id={data.page.id}
                acfFlexibleContent={data.page.acfFlexibleContent}
              />
            ) : (
              ""
            )
          }
        </div>
      </div>
    )
  }
}

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      content
      # ACF Home
      acf_home {
        homepageCategorySelector {
          category {
            ... on WpPage {
              id
              title
              slug
              link
            }
          }
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }

      # ACF Flexible Content
      acfFlexibleContent {
        pageBuilder {
          # Flexible Content Image Row FullWidth
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRowFullwidth {
            imageCaption
            fieldGroupName
            image {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageFocalPoint
            imageHoverFocalPoint
            imageHoverIsDark
            imageHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }

          # Flexible Content Image Row 21
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRow21 {
            imageLargeCaption
            imageSmallCaption
            fieldGroupName
            imageLarge {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeHoverIsDark
            imageSmallHoverIsDark
            imageLargeHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmall {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmallHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeFocalPoint
            imageLargeHoverFocalPoint
            imageSmallFocalPoint
            imageSmallHoverFocalPoint
          }

          # Flexible Content Image Row 12
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRow12 {
            imageLargeCaption
            imageSmallCaption
            fieldGroupName
            imageLarge {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeHoverIsDark
            imageSmallHoverIsDark
            imageLargeHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmall {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmallHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeFocalPoint
            imageLargeHoverFocalPoint
            imageSmallFocalPoint
            imageSmallHoverFocalPoint
          }

          # Flexible Content Image Row 111
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRow111 {
            image1Caption
            image2Caption
            image3Caption
            fieldGroupName
            image1 {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2 {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image3 {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image1HoverIsDark
            image2HoverIsDark
            image3HoverIsDark
            image1Hover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2Hover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image3Hover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image1FocalPoint
            image1HoverFocalPoint
            image2FocalPoint
            image2HoverFocalPoint
            image3FocalPoint
            image3HoverFocalPoint
          }
        }
      }
    }
  }
`
