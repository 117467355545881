import React from 'react';
import styled from 'styled-components';
import HomepageCategory from './HomepageCategory';

const HomepageCategoriesWrapper = styled.div`
padding-bottom:8px;
`;

export default function HomepageCategories(data) {
  const acf_home = data.HomepageCategories.homepageCategorySelector;

  return (
    <HomepageCategoriesWrapper >
      {acf_home ? acf_home.map((node,i) => {
        
        const slug = node.category.link;
        const title = node.category.title;
        const image1 = node.image1;
        const image2 = node.image2;

        return <HomepageCategory slug={slug} title={title} image1={image1} index={i} key={node.slug+'_'+i} image2={image2} />
        
      }): "" }
    </HomepageCategoriesWrapper>
  )
}


